import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'

class Services extends Component {
  render() {
    return (
      <Fragment>
          <Container className='text-center ptb-50 product-name-on-card'>
              <div className='section-title text-center'>
                <h2>Medical Universities for</h2>
                <p>Study MBBS in Russia</p>
              </div>
              <Row>

               <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>              
               <Card.Body>
  <h5>Rostov State <br />Medical University</h5>

   
             </Card.Body>
             </Card>
              </Col>

              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
            
               <Card.Body>
               <h5> Kazan State<br /> Medical University</h5>
 
   
             </Card.Body>
             </Card>
              </Col>

              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
             
               <Card.Body>
               <h5> Crimea<br /> Federal University</h5>
 
   
             </Card.Body>
             </Card>
              </Col>

             
              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
             
               <Card.Body>
               <h5>North-western State<br /> Medical University</h5>
 
   
             </Card.Body>
             </Card>
              </Col>

              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
              
               <Card.Body>
               <h5>Kazan<br /> Federal University</h5>
   
             </Card.Body>
             </Card>
              </Col>

              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
              
               <Card.Body>
               <h5>Petrozavodsk state<br /> university</h5>

 
   
             </Card.Body>
             </Card>
              </Col>


              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
              
               <Card.Body>
               <h5>Peoples’ Friendship<br /> University</h5>


 
   
             </Card.Body>
             </Card>
              </Col>

              <Col className='p-1' key={1} xl={3} lg={3} md={4} sm={6} xs={12}>
               <Card className='image-box card'>
              
               <Card.Body>
               <h5>Yaroslavl state<br /> medical university</h5>



 
   
             </Card.Body>
             </Card>
              </Col>

              </Row>
          </Container>
          </Fragment>
    )
  }
}

export default Services
