import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Kazan_federal_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Kazan federal university / Admissions / Top University</title>
 <meta name="description" content="Kazan federal university, Admissions 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/kazan-federal-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University,Admissions 2022,Toll-free 1800-572-5827" />
	<meta property="og:description" content="Kazan federal university, Admissions 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/kazan-federal-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Kazan federal university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Kazan Federal University</h1>
<p>Kazan Federal University is one of the oldest universities in Russia, it celebrated its 210th anniversary in 2014. We celebrated this date as another token of the university’s dedication to its longstanding academic mission as one of the leading educational institutions in Russia and in the world.</p>
<p>Kazan Federal University has gained its international fame thanks to its distinguished scholars and graduates whose achievements have had a beneficial effect on the whole of mankind. The unique culture of Kazan Federal University has shaped the development of Kazan and the Volga region.</p>
<p>The University’s new federal status obtained in 2010 poses new interesting challenges and calls for new approaches in the rapidly changing world. Kazan Federal University today is a dynamic modern University actively participating in local and international cooperation, networking with academia and industry, boosting the region’s human resources development as well as top-level research and innovation in different areas. 17 Centers of excellence and 80 world-class research and teaching laboratories have been established in recent years.</p>
<h2>Kazan Federal University main faculties for foreign students in English Medium is:</h2>
<ul>
<li>General Medicine</li>
<li>Dentistry</li>
<li>Pharmacology</li>
  </ul>
<p>Medicine is one of the most intensively developing Faculty of Kazan Federal University, which consists of more than 60 subdivisions: departments, research laboratories, clinical base in the Republican Clinical Hospital № 2, museums, the Botanical Garden etc.</p>
<p>KFU’s priority in medical training is teaching the fundamental approach that will enable graduates to become a new generation of doctors, able to understand the causes and mechanisms of the processes occurring in the body of a healthy or sick person. Kazan federal university is the best place for study Medicine with the high-class standard.</p>

          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Kazan_federal_university
