import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Kazan_state_medical_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Kazan state medical university / Admissions / Top university</title>
 <meta name="description" content="Kazan state medical university, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/kazan-state-medical-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University,Admissions 2022,Toll-free 1800-572-5827" />
	<meta property="og:description" content="Kazan state medical university, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/kazan-state-medical-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Kazan state medical university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Kazan State Medical University</h1>
<p>Kazan State Medical University is a multi-functional and multi-leveled state institution of higher learning in medicine. It meets both personal and social needs in acquiring education in medicine, in developing bio-medical and clinical sciences, as well as broadening scientific knowledge of medical sciences. It functions on the basis of self-governance and belongs to the system of Higher Education and Scientific Research of the Ministry of Health of Russia. Kazan State Medical University is recognized by WHO, MCI and another Medical councils of several countries. According to the Academic Ranking of World Universities-European Standard ARES-2014, published by European Scientific-Industrial Chamber, KSMU got the third place among the top Medical Universities in Russia. Studying MBBS in Russia, KSMU Kazan is the first choice for Indian Students nowadays.</p>
<h2>Faculties:</h2>
<ul>
<li>Faculty of General Medicine</li>
<li>Faculty of Pediatrics</li>
<li>Faculty of Dentistry</li>
<li>Faculty of Pharmacy</li>
<li>Faculty of Preventive Medicine</li>
<li>Faculty of nursing</li>
</ul>
          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Kazan_state_medical_university
