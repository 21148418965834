import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Features extends Component {
  render() {
    return (
      <Fragment>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p>Study MBBS in Russia : Russian universities present the highest standards of teaching for study mbbs in Russia. Universities meet all quality criteria, and are becoming more and more international. Excellent and open-minded professors, student-centered staff, modern facilities, and a variety of courses taught in foreign languages make Russian universities very attractive. Russia is a great country for MBBS Study. Here, you will find a wide selection of courses you can take at a low cost. Russian universities are eager to welcome foreign students.Education in Russia has a long and impressive academic tradition. In almost every big city, there are government institutes, academies, universities offering undergraduate and postgraduate education. Russian degrees and diplomas are recognized in the worldwide. Russia represents an opportunity to get to know one of the fastest developing countries. State Universities of Russia have a well-established reputation for excellence in teaching and research and is consistently ranked amongst the top universities in the world by both government and independent surveys.</p>
<h1>No Donation Needed at all for Study MBBS in Russia</h1>
<p>Although Russian medical universities are globally renowned for their quality education, yet you do not have to pay a hefty donation to make it into the merit list of the universities offering admission which usually on a first come first serve basis. The candidate has to pay only the annual fees, hostel charges as well as any other miscellaneous charges if any. We as a credible consultant MBBS. if an agent asks for any donation for getting admission in Russia, you should better stay away from such fool-makers because admission in Russian medical colleges and universities is accepted solely on merit without any donation.</p>
<h2>Important Guidelines And Information You Must Know Before Enrolling For MBBS Study in Russia</h2>
<Row>
<Col  key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
<ul>
  <li>Students must have detailed information about all the MCI recognized and WHO approved medical universities in Russia.</li>
  <li>Students must know about the course, curriculum, and other information.</li>
  <li>Students and parents should know about the detailed fee structure for both English and Russian MBBS program.</li>
  <li>You must be aware of the fact whether the particular college or university is government or private. You should also assess the difference in fees and facilities provided in government and private medical colleges and universities in Russia.</li>
  <li>You should also enquire about the eligible scholarship opportunity.</li>
  </ul>
</Col>
<Col  key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
<ul>
<li>The student aiming to do MBBS must be aware of the weather factor in mind as some cities experienced bitter cold during winter and the temperatures dip well below zero there.</li>
  <li>Students and parents must be aware of the latest update about the Visa rules and policies of the Russian Government.</li>
  <li>Apart from the tuition fees, the candidate must find out the possible estimate of accommodation, food, and other expenses in Russia.</li>
  <li>It is the student’s responsibility to ask and know about the prevailing education pattern</li>
  <li>The candidate should know about the USMLE, MCI screening exam preparation availability, and FMGE passing percentage.</li>
</ul>
    </Col>
</Row>
          </Col>
          </Row>
       </Container>

      </Fragment>
    )
  }
}

export default Features
