import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';
import InnerSlider from '../../assets/images/InnerSlider.webp';



class ContactSlider extends Component {
     render() {
     

    return (
     <Fragment>
 
 <Carousel variant="dark" className='bgSlider'>
 
  <Carousel.Item>
  <div className='overlay'></div>
    <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
   
    <Carousel.Caption>
      <h3>Contact Us</h3>
     
    </Carousel.Caption>
  </Carousel.Item>  
 </Carousel>

     </Fragment>
    )
  }
}

export default ContactSlider
