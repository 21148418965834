import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class North_western_state_medical_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>North-western State Medical University named after I. I. Mechnikov</title>
 <meta name="description" content="North-western State Medical University named after I. I. Mechnikov, Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/north-western-state-medical-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University,Admissions 2022,Toll-free 1800-572-5827" />
	<meta property="og:description" content="North-western State Medical University named after I. I. Mechnikov, Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/north-western-state-medical-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>North western state medical university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h4>North-western State Medical University named after I. I. Mechnikov was formed on October the 12-the, 2011, as a result of the merger of two oldest educational medical institutions in Russia – Saint-Petersburg Medical Academy of Postgraduate Studies and Saint-Petersburg State Medical Academy n. a. I.I. Mechnikov.</h4>
<h1>The founder of Mechnikov university is the Ministry <br/> of public health of the Russian Federation.</h1>
<p>The activity of the University is based on cooperation and coordination of educational, clinical and scientific activity. It makes possible to prepare competent specialists, who possess up-to-date knowledge and are able to use it in practice.</p>
<p>4,200 students (3000 students on budgetary education form and ~1200 on the fee-based educational form) are studying at the University, among them are 500 students from foreign countries.</p>
<p>Besides that, at the University are studying over 670 interns and about 1500 clinical residents, 460 Ph.D. candidates do their postgraduate work. Up to 35 000 specialists undergo their short- and long-term postdoctoral training annually.</p>
<p>Diagnostic and clinical work at Mechnikov University is managed in 6 own clinics, which capacity is 1645 beds and include 25 clinical specializations. Clinical subdivisions of the University provide high-skilled medical help to 40000 patients at the hospital and about 300000 outpatients yearly.</p>
<p>The research work at Mechnikov University is conducted in accordance with recent scientific development directions of biomedical sciences. Considerable attention is paid to studies in the field of health protection and sanitary and epidemiological welfare of the population. The long-term strategy is focused on the effective transfer of fundamental and applied scientific action results and on building supportive scientific-educational environment, which will make possible to prepare specialist of highest qualification.</p>
<h2>Faculties:</h2>
<ul>
<li>Faculty of General Medicine</li>
<li>Faculty of Preventive Medicine</li>
<li>Pediatric faculty</li>
<li>Dental faculty</li>
<li>Surgical faculty</li>
<li>Therapeutic faculty</li>
<li>Biomedical faculty</li>
<li>Faculty of Nursing Care</li>
  </ul>

          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default North_western_state_medical_university
