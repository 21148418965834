import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';
import BgSlider from '../../assets/images/BgSlider.webp';



class AdmissionBottom extends Component {
     render() {
     

    return (
     <Fragment>

<Container fluid={true} className='bgSlider2'>
         <Container>
<Row>
<Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h3>A GATEWAY TO RUSSIA-RREC<br/ > (Reliable Russian Educational Consultants)</h3>

<h3>Email: contact@rrec.com.ru, rrec.educonsultants@gmail.com</h3>

<h3>Students or parents can write to us<br /> at WhatsApp also +79515174507 (24*7)</h3>



  </Col>
</Row>
</Container>
       </Container>
 
      </Fragment>
    )
  }
}

export default AdmissionBottom
