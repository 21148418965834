import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Izhevsk_state_medical_academy extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Izhevsk state medical academy / Admissions / Top university</title>
 <meta name="description" content="Izhevsk state medical academy, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/izhevsk-state-medical-academy/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Izhevsk state medical academy, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/izhevsk-state-medical-academy/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Izhevsk state medical academy</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Izhevsk state medical academy</h1>
<p>Izhevsk state medical academy is a leading higher medical education provider in the Russian Federation. It is proud of its highly-qualified teaching staff, advanced researchers and experienced physicians whose names are world-famous. The academy was established in 1933 and over the course of 80 years, it has trained more than 21500 doctors and nursing managers. Nowadays 351 teachers including 86 Doctors of Medicine and 212 Candidates of Medicine provide a high level of teaching process at 52 departments and 28 clinical bases of the academy. Over 2800 students study at the academy including the foreign ones. The academy has been training international students since 1988. Students from 113 states of the former Soviet Union, Europe, Asia, Latin America, and Africa have studied at our academy. Over this period 3000 physicians, dentists, and pediatricians have been trained for foreign countries.</p>
<p>Izhevsk State Medical Academy is one of the famous centers of medical education and science with a long and glorious history. Studying at the Medical Academy is much more difficult than in other universities because the future doctor simply cannot allow himself not to know or understand something. We are proud of our teachers, graduates and highly skilled professionals who successfully work for the benefit of medical science and practical healthcare. To be worthy of our history we are working hard every day and are optimistic about our future. High professionalism of our staff, the development of effective medical, educational and social technologies, new scientific studies are all the keys to the successful work for the benefit of the society what is the most important mission of Izhevsk State Medical Academy.</p>
<p>The education process is organized into four departments, including General Medicine, Paediatrics, Dentistry, and Department of Post-graduate and Professional Training. The academic staff regularly participates in international conferences and symposia. Practical courses are taught at the best city hospitals and out-patient clinics which enables us to apply the most advanced teaching methods.</p>
<h2>Below you can find out brief information and contact details of each faculty.</h2>
<ul>
<li>GENERAL MEDICINE</li>
<li>Pediatrics</li>
<li>DENTISTRY</li>
<li>DEPARTMENT OF POST-GRADUATE AND PROFESSIONAL TRAINING</li>
<li>Center FOR PRACTICAL SKILLS</li>
<li>Center FOR PRE-ACADEMY AND ADDITIONAL TRAINING</li>
  </ul>


          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Izhevsk_state_medical_academy
