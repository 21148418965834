import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap'


export class Faq extends Component {
    render() {
        return (
           <Fragment>
         <Container className='text-center faq' fluid={true}>
         <div className='section-title text-center'>
                <h2>Frequently Asked Questions</h2>
             
              </div>
         <Container>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
         
         
          <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header><h4>Would the student have to give any entrance examination prior to joining the university in Russia</h4></Accordion.Header>
    <Accordion.Body>
   <p>Unlike the other countries, there are no pre-qualifying examinations like NEET, TOEFL, IELTS, etc for admission to the Universities in Russia. The admissions are based on the 12th standard marks for the Graduate courses and as per the equivalency of the Indian graduate degree with the Russian degrees for the postgraduate courses. All admissions are strictly on a first-come-first-served basis.</p> 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><h4>What are Russian classroom environments like?</h4></Accordion.Header>
    <Accordion.Body>
    <p>In most Russian universities, there is generally a teacher-student ratio of 1:10, which gives you a more attentive and personal learning environment.</p>
    </Accordion.Body>
  </Accordion.Item>


<Accordion.Item eventKey="2">
    <Accordion.Header><h4>What if the classes are conducted in Russian? I don't even speak Russian!</h4></Accordion.Header>
    <Accordion.Body>
    <p>If the medium of instruction for your intended course is Russian, you will receive an academic year-long Preparatory course conducted in Russian before you begin your actual university studies. This course teaches you, Russian, in more detail, and also covers the prerequisite subjects that you need to know to begin your university studies. But for foreign students Courses are available in English Medium only and to learn the Russian language for foreign students there are six hours of classes in a week.</p>
    </Accordion.Body>
  </Accordion.Item>


  <Accordion.Item eventKey="3">
    <Accordion.Header><h4>What if the classes are conducted in English? Do I need to be proficient in Russian?</h4></Accordion.Header>
    <Accordion.Body>
    <p>For medical studies, the first three years consist of pre-clinical training and are more lectures oriented. The pre-clinical subjects are taught completely in the English language. However, there are Russian language classes as a part of the curriculum from the first year itself. This is necessary since from the fourth year the students would start his clinical subjects, which would require him/her to attend the clinics and hospitals as part of his training and would have to interact with the local Russian patients and hence would require knowing the local language. Students are therefore required to be proficient in the Russian language so that they are able to gain the most out of their higher education in Russia. Also, the knowledge of the local language is an added asset for the student to interact with the local population. In short, proficiency in Russian is a MUST if you choose to enroll in Russian Higher Education.</p>
    </Accordion.Body>
  </Accordion.Item>
  
   <Accordion.Item eventKey="4">
    <Accordion.Header><h4>Where will I live?</h4></Accordion.Header>
    <Accordion.Body>
    <p>The university you choose will provide comfortable basic student accommodation with common kitchen facilities. All hostels are supplied with cold and hot water and central heating system, each room contains beds, a desk, chairs, and storage space and telephone facility. The cost is included in the yearly hostel fee that is paid to the University. Student hostel will provide you with an exciting opportunity to learn more about Russian culture and students cultures from other countries. For those who prefer to live out of university, private apartments are available, but students will have to rent them on their own and to take all responsibility.</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="5">
    <Accordion.Header><h4>Where will I eat?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Students can dine at low-priced cafeterias which are located in the University. Alternatively, there are cafes, restaurants and fast-food chains (McDonald's, KFC, Pizza Hut etc) in the cities where you can dine. Student hostels usually provide shared kitchen facilities for those who prefer to cook their meals. There are many supermarkets and stores where you can purchase your food if you choose to cook. Shared cooking among international students is also a popular activity.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="6">
    <Accordion.Header><h4>What about foodstuffs and toiletries? Should I bring them with me?</h4></Accordion.Header>
    <Accordion.Body>
    <p>It may be better to take a few extra dollars with you as you can buy practically every kind of food and toiletry in Russia. It is recommended, however, that you bring something just for the first few days.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="7">
    <Accordion.Header><h4>Are student hostels in or near the campus?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Yes. If not, how do I travel to classes? For some institutions, the hostel is walking distance to the campus, but for others, some traveling is required. The normal mode of transportation for students is by bus, trolleybus, tramway, and subway (underground trains,). Russia has an efficient and extensive public transportation system. Buses and underground trains are reasonably priced and easily accessible.</p>
    </Accordion.Body>
  </Accordion.Item>
  
  
       <Accordion.Item eventKey="8">
    <Accordion.Header><h4>How do I contact my parents or friends?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Russia may seem a long way from home, but, thanks to its excellent communication services, you will find it easy to keep in touch with your family and friends. With the growing number and variety of telephone and other communication services, you will have no trouble staying in close contact with your family and friends. You can contact by Internet services (WI-FI) or by phones software Whatsapp, Mig33, VoIP Buster, Qiwi, Skype. Etc.very cheap calling by using all these software.</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="9">
    <Accordion.Header><h4>Does the student need a visa to enter Russia to Study? How does one apply for a visa?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Yes, international students require a visa to enter Russia for their education. The visa application must be submitted together with the Application for Admission to Higher Education in Russia. Upon acceptance to the university, the university will issue the student an official letter of invitation. We will submit this letter together with the visa application to the local Russian embassy or consulate for processing.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="10">
    <Accordion.Header><h4>How do I make my travel arrangements?</h4></Accordion.Header>
    <Accordion.Body>
    <p>We as part of our services assists the students to make their travel arrangements like air ticket, immigration clearance, etc.</p>
    </Accordion.Body>
  </Accordion.Item>


      <Accordion.Item eventKey="11">
    <Accordion.Header><h4>Will I be accompanied on my trip to enroll at University for the first time?</h4></Accordion.Header>
    <Accordion.Body>
    <p>First-time students going to Russia are required to travel with us in a group with other students. As part of our services, Students will be accompanied and met at the Airport and will go together to the University to register and to check-in to student hostels.</p>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="12">
    <Accordion.Header><h4>Are the medical degrees from Russian Universities recognized?</h4></Accordion.Header>
    <Accordion.Body>
   <p>YES. As per the amendment to the Indian Medical Act, 1956, any Indian student intending to study medicine in any medical college anywhere in the world for a graduate medical course has to obtain an eligibility certificate from the Medical Council of India, New Delhi before joining the medical college abroad. After the successful completion of the course, the student is expected to appear for a screening test conducted by the National Board of Examination (www.natboard.org) as required by the Medical Council of India (www.mciindia.org) prior to the registration with the Medical Council of India. More details could be obtained from the Medical Council of India either from their office in New Delhi or from their official website. The responsibility of obtaining the eligibility certificate as required by the Medical Council of India rests with the student.</p>
<p>Post Graduate courses from nowhere in the world are registered with the Medical Council of India. This would mean that you would be unable to neither work as a specialist in a government hospital nor teach the specialization learned. You would, however, have your own private practice or work in a private hospital. Since you would be doing your specialization from a University, which is listed by the WHO in its &ldquo;Directory of world medical schools&rdquo;, you would be eligible to appear for screening examinations to many screening examinations like PLAB, USMLE.</p>
<p><strong>Note please:</strong> All students have to follow the rules of their Medical Councils of their countries before the admission process.</p>

    </Accordion.Body>
  </Accordion.Item>
  
   <Accordion.Item eventKey="13">
    <Accordion.Header><h4>Are there any scholarships or grants given to foreign students?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Education in Russia is highly subsidized by the Government of the Russian Federation. Therefore there are no separate scholarships or grants available from individual universities. However, educational bank loans for abroad studies up to 15 lakhs are now available from all nationalized, co-operative and the private sector banks. These loans are available at very low interest and are generally repaid in easy installments, one year after the completion of the educational period.</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="14">
    <Accordion.Header><h4>Do You help the students with the bank Loans?</h4></Accordion.Header>
    <Accordion.Body>
    <p>We guide the student/parent with regards to the bank loans available and also guide the students about the loan formalities. We also support the student with all the documents that he/she would require from the University. However, the student/parent would have to fulfill the eligibility regarding the issuance of the bank loan and the responsibility of securing the loan would remain with the student.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="15">
    <Accordion.Header><h4>How do I apply for my passport?</h4></Accordion.Header>
    <Accordion.Body>
    <p>For obtaining a passport, you need to apply to your local passport office in an application form available at the passport office. It would, however, be an easier process for the student to apply through a travel agent since he is well aware of the process. The process normally takes around 4 to 5 weeks for the issuance of the passport after the date application and the passport is sent directly to your address. However, it is known that the issuance of the passport makes take a longer time, which may result in a delay in proceeding to Russia or even the cancellation of your admission. Therefore, you should take all precautions and plan accordingly. There is also a facility to have your passport issued on a "Tatkaal" basis on the submission of your letter of admission which will take 5-7 days.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="16">
    <Accordion.Header><h4>This is my first extended stay in another country. What can I expect?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Life in Russia is very different from home. Things that are taken for granted as routine at home may be very time to consume and frustrating in Russia. Also, life in student housing can be very challenging. Besides taking care of yourself, you will have to adapt and live with students from all over the world whose cultures are very different from yours. It is very important that you learn to adapt and handle each situation with maturity and poise.</p>
    </Accordion.Body>
  </Accordion.Item>
  
  
       <Accordion.Item eventKey="17">
    <Accordion.Header><h4>What is student life like in Russia?</h4></Accordion.Header>
    <Accordion.Body>
    <p>It is not all hard work when studying in Russia. Learning is not just confined to the classroom. There is a wide range of cultural activities to check out. Universities can arrange trips which provide an insight into the culture, history, and geography of Russia. There are also museums and galleries to explore. Students enjoy a wide selection of extracurricular activities that may include drama productions, horse riding, pilot lessons, and sports. There are also concerts, parties, and camping trips that are organized by the student unions. With most universities located in communities, many social and recreational activities and facilities are available to students; these include opera houses, ballets, and plays. There are also numerous yearly traditional and cultural events to look forward to.</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="18">
    <Accordion.Header><h4>It is known that Russia is a very cold country, how are the students protected from the cold?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Even though Russia is a cold country, severe winters would last for 2 – 3 months in a year. All the establishments like homes, hostels, classrooms, shops etc as well as the public transports are centrally heated. Students are also advised to buy reasonably priced water and room heaters should they find the heating arrangements to be inadequate. Every student should also take care of wearing warm clothes like fur coats, caps, gloves etc when they go outdoors. At others times the weather is Russia is not as cold as it is made out to be with temperatures between +20 degrees.</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="19">
    <Accordion.Header><h4>Can a student earn while he studies?</h4></Accordion.Header>
    <Accordion.Body>
    <p>A student enters into the Russian Federation on an invitation, which is issued by the University, which is an educational institution. This invitation and the subsequent visa issued is a "Student visa". Therefore the student is not legally allowed to take on any employment nor could do a business activity. Any violation may result in expulsion from the University and subsequent deportation from the country. We on its part advises students to concentrate on education, the main reason for going to Russia and refrain from any money making activities.</p>
    </Accordion.Body>
  </Accordion.Item>


      <Accordion.Item eventKey="20">
    <Accordion.Header><h4>May I practice my religion in Russia?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Yes, you can. Russia is home to over 100 nationalities and ethnic groups, as well as different religions. The Russian Orthodox Church is the largest religious group while Islam is the second most practiced religion.</p>
    </Accordion.Body>
  </Accordion.Item>
  
  
   <Accordion.Item eventKey="21">
    <Accordion.Header><h4>Does the student have to be present personally for an interview at the Russian Embassy / Consulate for the issuance of the visa?</h4></Accordion.Header>
    <Accordion.Body>
    <p>As part of the services offered by us the passport of the student would be submitted by us to the visa section of the diplomatic mission of the Russian Federation in India and the student is not expected to appear for a personal interview. But for other countries or Africa and Arabian students, they have to follow the Embassy rules for an interview.</p>

 <p><strong>Note please:</strong> To get a student Visa for Russia it's very easy as compare to other countries, that's why every year thousands of foreign students entering Russia for Under-graduate and post-graduate courses.</p>
    </Accordion.Body>
  </Accordion.Item>
  
     <Accordion.Item eventKey="22">
    <Accordion.Header><h4>Can my parents or family visit me while I am in Russia for Study?</h4></Accordion.Header>
    <Accordion.Body>
    <p>Of course, they can. However, the Russian universities and the Russian government is not responsible for the travel expenses or arranging accommodation for your family. Students must apply to the University or through us for an Invitation letter for your family. With this letter, your family can apply for a visa for entry into Russia at the local Russian Embassy or Consulate which allows visiting Russia for 2-3 weeks. (Tourist visa)</p>
    </Accordion.Body>
  </Accordion.Item>
  
       <Accordion.Item eventKey="23">
    <Accordion.Header><h4>The Government Facilitate Subsidy On Education</h4></Accordion.Header>
    <Accordion.Body>
    <p>Since medical universities in Russia are government universities the cost of doing MBBS in Russia does not actually cost you what it should be. The government provides subsidy on education that helps both students as well as the universities to produce the opportunity to pursue medical courses at an affordable cost. This is one of the major reasons why the medical fees, living costs in the university hostel, etc are quite cheap as compared to other foreign universities in the USA, Europe as well as in India.</p>
  <h3>World-Class Education And Infrastructure</h3>
<p>The low-cost medical education in Russia hardly means that students have to compromise with the quality of teaching and the level of infrastructure provided by the university or college. It is needless to say that you are going to get world-class medical education when you study MBBS in Russia. The universities are well-equipped with the best medical infrastructure. The degree is also worldwide accepted. All this makes Russia a hotspot for Indian students and a favorite destination for many aspirants who want to do MBBS in Russia in the pursuit of a globally accepted and renowned medical degree. Hence, there is no point of hesitation in choosing this land for the MBBS course.</p>
<h3>Campus Life And Extra Curricular Activities For MBBS Students In Russia</h3>
<p>During the MBBS program in Russia, the candidates are also facilitated and expose to the international campus environment and provide equal opportunity to excel in extracurricular and cultural activities held during medical education. It is to inculcate the overall development of the students. Moreover, the campus life and robust infrastructure of the specific college or university also play a key role in all-round development. Here is the glance of campus life you are going to experience during MBBS in Russia</p>
<ul>
  <li>Excellent and impeccable infrastructure is provided in the universities.</li>
  <li>The cost of such a sound infrastructure is also very minimal.</li>
  <li>The Campus is quite safe and secured for al foreign students.</li>
  <li>Students get the opportunity to mix and mingle with students of different backgrounds and cultures as students from different parts of the world come to study MBBS in Russia.</li>
  <li>Students can participate in various cultural events and festivals held at the universities. It also allows them to know each other and their cultures.</li>
  <li>Students also participate in different types of sport and extracurricular activities. Many universities offer sports infrastructures as well such as basketball court, football ground, badminton and tennis court, and so on.</li>
  <li>Gyms are also part of universities' sports and extracurricular facilities.</li>
</ul>
    </Accordion.Body>
  </Accordion.Item>



</Accordion>
          </Col>
          </Row>
          </Container>
          </Container>
           </Fragment>
            )
        }
    }
    export default Faq;