import React, { Component, Fragment } from 'react'
import Services from '../components/home/Services';
import HomeSlider from '../components/home/HomeSlider';
import Features from '../components/home/Features';
import Faq from '../components/layout/Faq';
import { Helmet } from "react-helmet";





export class Home extends Component {
  render() {
    
    return (
    <Fragment>
      <Helmet>
 <title>STUDY MBBS IN RUSSIA FOR INDIAN STUDENTS</title>
 <meta name="description" content="Admission procedure for study in Russia, Admission 2022-23 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Reliable Russian Educational Consultants » Feed" />
	<meta property="og:description" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta property="og:url" content="https://studyinrussia.in/" />
	<meta property="og:site_name" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet>   
      <HomeSlider />
      <Services />
      <Features />
      <Faq />
        </Fragment>
    )
  }
}

export default Home;
