import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Crimea_federal_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Crimea federal university</title>
 <meta name="description" content="Crimea federal university, Admission 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities is open now." />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/crimea-federal-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Crimea federal university, Admission 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities is open now." />
	<meta property="og:url" content="https://studyinrussia.in/crimea-federal-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Crimea federal university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Crimea Federal University</h1>
<p>Crimea Federal University is one of the top universities of Russia. The medicine of general faculty is one of the top priorities of Indian Students for MBBS. It is the institution of higher education situated in Simferopol (Crimea, Russia). According to some independent estimation, it is one of the most prominent medical schools in Russia. The University has 6 faculties and 54 departments.</p>

<p>History of the Medical Academy of Crimea Federal University goes back to the beginning of the 21st century: in 1918 it was medical faculty of Taurida University. In 1931 it separated and became an autonomous unit – Crimea Medical Institute, Since 1995 the higher school has been named in honor of Sergey Ivanovich Georgievsky (rector of the institute in 1951-1970). In 1998 the higher school became a university with the 4th (highest) accreditation level.</p>

<p>University holds many international and national forums, conferences, events, meetings, and roundtables. The University has cooperation agreements with the leading national and foreign higher education institutions, regulatory bodies, major companies and enterprises of the Russian Federation and the Republic of Crimea. Being committed to promoting the development of research and science in the region, it initiates the establishment and development of various research and science institutions comprising students and faculty and staff representatives.</p>

<p>University's production facilities, its active cooperation with the companies of related industries provide students with the chance to gain practical skills and open a number of employment opportunities.</p>

<p>Training is delivered in a variety of laboratories, including language laboratories, fully-equipped specialized classrooms, and multimedia classrooms, at student farms and partner companies. Students have at their disposal a unique, well-equipped research library, rich both in training materials and research and fiction books. The University maintains strong relations with its international partners in the framework of various research projects, students, and faculty internship programmes.</p>

<p>Today, Crimean Federal University trains future professionals using novel opportunities that meet high-level national and global standards in education. The number of these opportunities has increased significantly with the establishment of the new federal-level university through the merger of leading Crimean universities. V.I. Vernadsky Crimean Federal University has developed into a major research and science center and a source of pride for the Russian Federation and the Republic of Crimea.</p>

<h2>FACULTIES:</h2>
<ul>
<li>Faculty of General Medicine</li>
<li>Faculty of Dentistry</li>
<li>Faculty of Pharmacy</li>
<li>Faculty of Clinical Psychology</li>
<li>Simferopol City, Crimea</li>
</ul>

<p>The city of Simferopol is the capital of the Republic of Crimea. The city is located along the river Salgir. The city is well connected to nearby areas through roads, railway & an international airport. The city receives thousands of tourists each year from different parts of the world. Crimea Federal University is one of the largest federal university in Russia and the top destination for study MBBS.</p>

<p>The Republic of Crimea is surrounded completely by the Black Sea. The Crimea Federal University has witnessed a huge growth in the influx of foreign students, especially from India for MBBS admission in Crimea due to the popularity the university has gained in recent years. </p>
          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Crimea_federal_university
