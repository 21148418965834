import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import AdmissionSlider from '../components/home/AdmissionSlider'
import AdmissionBottom from '../components/home/AdmissionBottom';
import { Helmet } from "react-helmet";

export class Admission_procedure_for_study_in_russia extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
 <title>Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don</title>
 <meta name="description" content="Admission procedure for study in Russia, Admission 2022-23 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/admission-procedure-for-study-in-russia/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Reliable Russian Educational Consultants » Feed" />
	<meta property="og:description" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta property="og:url" content="https://studyinrussia.in/admission-procedure-for-study-in-russia/" />
	<meta property="og:site_name" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
        <AdmissionSlider />
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h4>Study in Russia-specialties | RREC (Study in Russia) | Study in Russia: Doctors-IEA|MBBS in Russia | Study Medicine in Russia <br/> Study in Rostov, Russia|Study in Kazan, Russia | Medical study in Russia | Study MBBS in Russia </h4>

<h1>STUDY IN RUSSIA:<br/>There are three stages of submitting documents for admission<br/> to a Russian state university:</h1>
<h4>STEP 1: Submission of documents to the university to confirm the possibility of admission; issue of an admission letter.</h4>

<h2>You should firstly fill in the application form online with the following:</h2>
  <ul>
  <li>Application form (given by our representative);</li>
  <li>Copy of your passport (pages with the photo, date of birth and passport expiry date);</li>
  <li>Certificate of complete higher secondary education with an academic transcript for undergraduate programs / higher education degree with an academic transcript for postgraduate programs.</li>
  </ul>
  
<p>IMPORTANT: Acceptance of applications is carried out in accordance with the quota of places provided by Russian state universities for foreign students. Thus, once you have made your choice of the university, we recommend you not to postpone the submission of your application. Places are limited.</p>

<h3>STEP 2: Issuance of student visa</h3>

<p>Upon receiving the invitation letter for study, the student should refer to the Embassy Consulate of the Russian Federation in his / her country in order to apply for a student visa. Usually, the following documents are required:</p>
  <ul>
  <li>Passport;</li>
  <li>Completed application form (issued by the Embassy of the Russian Federation in your country);</li>
  <li>3 photographs (3.5×4.5mm);</li>
  <li>Original of the invitation letter for study;</li>
  <li>Original of negative HIV test results;</li>
  <li>Certificate of complete higher secondary education;</li>
  </ul>
  
<h3>STEP 3: Entry to Russia</h3>
  
<p>In order to enter the territory of the Russian Federation and to be admitted to the university, a student should have the following documents:</p>
  <ul>
  <li>Passport (valid for not less than 2 years);</li>
  <li>Student entry visa;</li>
  <li>Immigration Card (supplied during the flight or at the airport; it must be completed by the student and stamped by customs in the airport right after arrival in Russia);</li>
  <li>HIV test result;</li>
  <li>12 passport size colored photographs in the matte finish.</li>
    </ul>
<p><strong>IMPORTANT:</strong> All students are met personally at international airports by <a href="http://www.rrec.com.ru/">RREC (Study in Russia)</a> and have the special power of attorney from the university, which gives them the right to meet foreign students coming to Russia for study. According to the official rules and regulations of the Russian Federal Migration Service, a student can be refused entry to the Russian Federation if he/she is not met by an official from the university or a person who has power of attorney from the university. </p>

          </Col>
          </Row>
       </Container>
<AdmissionBottom />
      </Fragment>
    )
  }
}

export default Admission_procedure_for_study_in_russia
