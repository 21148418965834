import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';
import BgSlider from '../../assets/images/BgSlider.webp';



class ContactBottom extends Component {
     render() {
     

    return (
     <Fragment>
       <Container fluid={true} className='bgSlider2'>
         <Container>
<Row>
<Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
<h5>RREC having representatives more than 28 countries across the globe.<br /> RREC is the authorized representatives of Top Medical & Technical Universities of Russia<br /> for Asian, African, Arabian & European students, Student can apply with us for Direct Admissions for Study in Russia!</h5>

<h4>Office Working Hours</h4>
<h4>Monday-Friday: 9:00-18:00</h4>
<h4>Saturday: 10:00 AM – 16:00 </h4>
<h4>Sunday: Holiday | Support, Help, and Assistance: 24 hours </h4>

<h3>Study in Russia with Reliable Russian Educational Consultants <br/> Study Medicine in Russia with RREC</h3>


  </Col>
</Row>
</Container>
       </Container>

     </Fragment>
    )
  }
}

export default ContactBottom
