import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';
import Menu from './Menu';
import News from  './News';




 class Header extends Component {
     componentDidMount(){
          window.scroll(0,0)
     }
     render() {
          return (
               <Fragment>
               <News />            
              <Menu />
               </Fragment>
          )
     } 
}
export default Header