import React, { Component, Fragment } from 'react'
import Logo from '../../assets/images/logo.webp';
import {Link} from "react-router-dom";

class MainLogo extends Component {
  render() {
    return (
      <Fragment>
  <img className="nav-logo" src={Logo} />
      </Fragment>
    )
  }
}

export default MainLogo
