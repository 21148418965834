import React, { Component, Fragment } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Link } from 'react-router-dom';

import Home from '../pages/Home';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Admission_procedure_for_study_in_russia from '../pages/Admission_procedure_for_study_in_russia';
import Contact_us from '../pages/Contact_us';
import Rostov_state_medical_university from '../pages/Rostov_state_medical_university';
import Kazan_state_medical_university from '../pages/Kazan_state_medical_university';
import North_western_state_medical_university from '../pages/North_western_state_medical_university';
import Kazan_federal_university from '../pages/Kazan_federal_university';
import Petrozavodsk_state_university from '../pages/Petrozavodsk_state_university';
import Yaroslavl_state_medical_university_2 from '../pages/Yaroslavl_state_medical_university_2';
import Izhevsk_state_medical_academy from '../pages/Izhevsk_state_medical_academy';
import Peoples_friendship_university from '../pages/Peoples_friendship_university';
import Crimea_federal_university from '../pages/Crimea_federal_university';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {
  render() {
    return (

      <Fragment>    
     <Header />
     <Router basename={process.env.PUBLIC_URL} history={history}>  

      <Route exact path='/' component={ Home } /> 
      <Route exact path='/admission-procedure-for-study-in-russia' component={ Admission_procedure_for_study_in_russia } /> 
      <Route exact path='/Contact-us' component={ Contact_us } /> 

      <Route exact path='/rostov-state-medical-university' component={ Rostov_state_medical_university } /> 
      <Route exact path='/kazan-state-medical-university' component={ Kazan_state_medical_university } /> 
      <Route exact path='/north-western-state-medical-university' component={ North_western_state_medical_university } /> 
      <Route exact path='/kazan-federal-university' component={ Kazan_federal_university } /> 

      <Route exact path='/petrozavodsk-state-university' component={ Petrozavodsk_state_university } /> 
      <Route exact path='/yaroslavl-state-medical-university-2' component={ Yaroslavl_state_medical_university_2 } /> 
      <Route exact path='/izhevsk-state-medical-academy' component={ Izhevsk_state_medical_academy } /> 
      <Route exact path='/peoples-friendship-university' component={ Peoples_friendship_university } /> 
      <Route exact path='/crimea-federal-university' component={ Crimea_federal_university } /> 

    </Router>  
    <Footer />
 </Fragment>

    )
  }
}

export default AppRoute;
