import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Yaroslavl_state_medical_university_2 extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Yaroslavl state medical university / Admissions / Top university</title>
 <meta name="description" content="Yaroslavl state medical university, Admission 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/yaroslavl-state-medical-university-2/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Yaroslavl state medical university, Admission 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/yaroslavl-state-medical-university-2/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Yaroslavl state medical university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Yaroslavl state medical university</h1>
<p>Yaroslavl state medical university was founded in 1944. And now it has more than half-a-century history. At present, YSMU is the big regional center of excellent quality medical education and biomedical research. It is also the leader in medical training and post-graduation education, retraining of specialists of practical healthcare, medicine, pharmacy, and high skilled academic staff for the central regions of Russia.</p>
<p>At present, about 3500 students are studying at YSMU (including foreign students). Medical training and education are conducted by 58 departments that have all the necessary equipment to provide high-quality education according to available Russian and international modern standards. At YSMU, there are prepared highly qualified specialists of all local hospitals and medical institutions and professionals from other regions of Russia. Today the staff of YSMU includes approximately 1310 employees. The academic staff of YSMU consists of more than 600 persons (instructors). Among them 340 persons have the M.D. &amp; Ph.D. titles (candidates of sciences, assistant and associate professors) there are more than 110 doctors of sciences and full professors. Many of them are members of different Russian and foreign international societies and are well-known scientists and clinicians not only in Russia but all over the world.</p>
<h2>Faculties in YSMU:</h2>
<ul>
<li>General Medicine Faculty,</li>
<li>Pediatric Faculty,</li>
<li>Pharmacy Faculty,</li>
<li>Stomatology Faculty,</li>
<li>Faculty of Middle-Range Professional Education and Pre-Education Study</li>
<li>Faculty of Post-Diploma Professional Education and Education of Personnel of Highest Qualification,</li>
<li>Faculty of Additional Professional Education.</li>
  </ul>


          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Yaroslavl_state_medical_university_2
