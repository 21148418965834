import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactBottom from '../components/home/ContactBottom'
import ContactSlider from '../components/home/ContactSlider'
import { Helmet } from "react-helmet";

export class Contact_us extends Component {
  render() {
    return (
      <Fragment>
         <Helmet>
 <title>Reliable Russian Educational Consultants » Comments Feed</title>
 <meta name="description" content="Reliable russian educational consultants, join us for Study in Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for this session is open now. For online applications visit our website www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/contact-us/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Reliable Russian Educational Consultants » Feed" />
	<meta property="og:description" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta property="og:url" content="https://studyinrussia.in/contact-us/" />
	<meta property="og:site_name" content="Admission Procedure for study in Russia / 2022-23 / RREC Rostov-on-Don" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
        <ContactSlider />
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
         <h3>Get in touch with us!</h3>
         <ul>
         <li>India Office: Pitampura, New Delhi Contact numbers: <a href="tel:+91-7042284508" target="_blank">+91-7042284508</a>, <a href="tel:+91-7042284509" target="_blank">+91-7042284509</a></li>
         <li>Emails (for students):<a href="mailto:rrec.educonsultants@gmail.com">rrec.educonsultants@gmail.com</a>, <a href="nailto:contact@rrecrussia.com">contact@rrecrussia.com</a></li>
         <li>Web:<a href="https://rrec.com.ru/" target="_blank">www.rrec.com.ru</a> , <a href="https://www.studyinrussia.in/" target="_blank">www.studyinrussia.in</a> </li>
         </ul>

          </Col>
          <Col  key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
          <h3>Get in touch with us!</h3>
         <ul>
         <li>Russia office: Suvorova 74-A, Rostov-on-Don, Russia</li>
         <li>Contact numbers: <a href="tel:+79604430042" target="_blank">+79604430042</a></li>
    </ul>

         </Col>
          </Row>
       </Container>
      <ContactBottom />
      </Fragment>
    )
  }
}

export default Contact_us
