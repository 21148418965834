import React, { Component, Fragment } from 'react'
import {Container,Row,Col} from 'react-bootstrap'

class News extends Component {
    render() {

        return (       
         <Fragment>   
            <Container fluid={true} className="news">
                <Container>
                <Row>
                <Col lg={10} md={10} sm={10}> 
                    <marquee scrollamount="3" direction="left" onMouseOver="stop()" onMouseOut="start();">	<span class="text-white">Breaking News: Admission is open for 2022. Book your seat today with RREC Team, Official Admission Partner of Top Russian Medical Universities; RostSMU, CrimeaFU, VolSMU, KazanFU, KazanSMU, NWSMU, NoSMA, KurskSMU, PFUR, UNN Nizhny Novgorod. Direct Admission with Less Processing fees.
</span></marquee> 
                    </Col>
                    <Col lg={2} md={2} sm={2}> 
                    <div id="google_translate_element"> </div>
                    </Col>
                </Row>
            </Container>
            </Container>
            </Fragment>	
     )
    }
}
export default News;