import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Socialmedia from './Socialmedia'


class Footer extends Component {
  render() {
    return (
     <Fragment>
        <Container className='text-center footer' fluid={true}>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <p>Copyright &copy; 2022  <span>Reliable Russian Educational Consultants</span>. All Rights Reserved</p>
          </Col>
          </Row>
          </Container>

    <Socialmedia />
     </Fragment> 



    )
  }
}

export default Footer
