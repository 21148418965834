import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Peoples_friendship_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Peoples' Friendship University / Admissions / Top university</title>
 <meta name="description" content="Peoples' Friendship University, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/peoples-friendship-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Peoples' Friendship University, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/peoples-friendship-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Peoples’ friendship university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <h1>Peoples' Friendship University of Russia (PFUR)</h1>

<p>Peoples' Friendship University of Russia (PFUR) was established in 1960 and is one of the paramount of excellence and repute in Europe for Higher Education. The University is situated in Moscow, the capital of Russia, one of the most beautiful cities with rich cultural life and legendary history of 900 years. Moscow is also the biggest educational center of Russia; about 200 Universities are situated there offering high quality and affordable education in all specialties. Moscow also happens to be the industrial and business capital of Russia.</p>

<p>With 46 years of its existence, the University has gained huge popularity all over the world as one of the large international, scientific and educational center. For the last 12 years, PFUR is constantly among the first five leading Universities from more than 3000 Russian Higher Education Establishments. One will find a fleet of more 1700 professors teaching at Peoples' Friendship University of Russia. The glorious track record and achieving spirit have brought in high net worth reputation. Prominent specialists, who achieved recognition in different fields of science consider honorable to teach at the University.</p>

<h2>MEDICAL FACULTY OF PFUR:</h2>

<p>The Medical faculty of PFUR has a first-class reputation both in Russia and abroad; its fame rests on the scientists working at the faculty and the graduates. Nowadays, more than 6400 professionals that have been educated at the Medical faculty are working in more than 110 countries of the world. Prominent scientists whose contribution to medical science is well-known, are lecturing and leading practical seminars at the faculty. In numerous laboratories with up-to-date diagnostic and research equipment, students attempt penetrating into the mysteries of structure and functioning of the human body and learn how to diagnose diseases and apply innovative methods of treatment. The members of the Medical faculty are actively implementing innovative educational programs aimed at teaching new medical technologies to medical staff.</p>

<p>Functioning at the faculty are the Students scientific society that helps students become familiar with research from the beginning; the Young medical doctor's community; the Students theatre «Hippocrates», the lecture center where lectures in literature, music, and history are presented. The pre-university education is provided at the «Medik» medical-biological school (preliminary courses).</p>


          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Peoples_friendship_university
