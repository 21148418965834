import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Petrozavodsk_state_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Petrozavodsk state university / Admissions / Top university</title>
 <meta name="description" content="Petrozavodsk state university, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/petrozavodsk-state-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University,Admissions 2022,Toll-free 1800-572-5827" />
	<meta property="og:description" content="Petrozavodsk state university, Admissions 2022 Russian UniversitiesDear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in" />
	<meta property="og:url" content="https://studyinrussia.in/petrozavodsk-state-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Petrozavodsk state university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <p>University PetrSU was founded in 1940 as the Finnish-Karelian University and gained its present name in 1956. Throughout its 75-year history, PetrSU has become the largest university in the North-European part of Russia and trained more than 60000 students. It successfully combines traditions of classical education and fundamental science with innovative decisions in the fields of high-tech scientific development.</p>
<p>PetrSU has several notable alumni, including eminent politicians and poets. It is regularly placed among the best universities of Russia according to different rankings (Green Metric World University Ranking – 2nd place among 100 best universities in Russia; «Interfax» and «Ekho Moskvi» independent national rating in the internalization category – 3rd place, etc.)</p>
<h1>ABOUT PETRSU:</h1>
<p>The university occupies 8 buildings throughout the city and operates a botanic garden, a swimming pool «Onego», several laboratories and a publishing house. Its library holds a total of around 1.4 million books. PetrSU is located in Petrozavodsk, but it also has a branch in Apatity, the city in the Murmansk region. Today more than 14800 students attend classes in various undergraduate, graduate and post-graduate programs. They are taught by over a thousand professors, including 550 PhDs, 145 Doctors of Science, 26 members of state academies of science.</p>
<p>The university is made up of 80 departments, 9 faculties, 7 educational institutes, and 8 research institutes.</p>
<h2>Main Faculties for foreign students:</h2>
<ul>
<li>Preparatory faculty</li>
<li>Faculty of Medicine</li>
<li>Faculty of Dentistry</li>
<li>Faculty of Pharmacy</li>
<li>Faculty of Pediatrics</li>
</ul>

          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Petrozavodsk_state_university
