import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Carousel } from 'react-bootstrap'

import { Helmet } from "react-helmet";
import InnerSlider from '../assets/images/InnerSlider.webp';
import InnerBottom from '../components/home/InnerBottom';

export class Rostov_state_medical_university extends Component {
  render() {
    return (
      <Fragment>
          <Helmet>
          <title>Rostov State Medical University,Admissions 2022, Toll-free 1800-572-5827</title>
 <meta name="description" content="Rostov state medical university, admissions 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in (OFFICIAL REPRESENTATIVE OF ROSTSMU)" />	
 <meta name="robots" content="index, follow" />
	<meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	<link rel="canonical" href="https://studyinrussia.in/rostov-state-medical-university/" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University,Admissions 2022,Toll-free 1800-572-5827" />
	<meta property="og:description" content="Rostov state medical university, admissions 2022 Russian Universities. Dear applicants, we would like to announce that the application for admission to all Russian State Universities for 2022 session is open now. You can visit our website for more details www.rrec.com.ru, www.studyinrussia.in (OFFICIAL REPRESENTATIVE OF ROSTSMU)" />
	<meta property="og:url" content="https://studyinrussia.in/rostov-state-medical-university/" />
	<meta property="og:site_name" content="Reliable Russian Educational Consultants" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet> 
      <Carousel variant="dark" className='bgSlider'>
 
 <Carousel.Item>
 <div className='overlay'></div>
   <img  className="d-block w-100" src={InnerSlider}  alt="First slide" />
  
   <Carousel.Caption>
     <h3>Rostov state medical university</h3>
    
   </Carousel.Caption>
 </Carousel.Item>  
</Carousel>
        <Container className='ptb-50 features'>
          <Row>
          <Col  key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <p>Rostov State Medical University is the largest basic training and research center in Southern Russia. Over the 85 years of its existence, it has become a diversified complex with 10 departments, Medical College, Military Training Center, and its own Clinic. Leading and advanced doctors of our country have formed on the basis of the Rostov State Medical University their major scientific schools, headed by the Honored Workers of Science, Employees of high school, Honored Doctors, Honored Workers of Health and Culture, as well as world-renown scientists.</p>
<p>Today in the Rostov State Medical University operate 92 departments, the youngest of them is the Department of Medical Law. More than six hundred teachers are constantly working introducing innovations and interactive forms of learning. Among them, there are more than 119 Doctors of Science, professors and about 350 PhDs. At the University there are training programs for doctors and medical organizations from all over the South of Russia. Every year about 8000 doctors are retraining on the Faculty Training, more than 1000 interns and residents — at the Faculty of Professional Retraining.</p>
<p>In addition, the University has a Preparatory Faculty on Training Foreign Citizens, because the Rostov State Medical University is an international medical school that trains representatives of more than a hundred nationalities and ethnic groups from all over the world.</p>
<p>Rostov State Medical University develops international scientific cooperation with many countries exchanging scientific staff, students, teachers, highly qualified specialists and participating in international scientific conferences and congresses.</p>
<p>The Clinic of our University is a multi-field medical institution, which has wide opportunities of modern diagnostic studies and a high level of effective specialized consultative, diagnostic and medical care to the population of the Rostov Region and other territories of the Southern Federal District and the Russian Federation. It is also the largest training base, which has all conditions for the educational process using the advanced forms and methods of training and modern achievements in medical science. In the Clinic, the students acquire the necessary skills and get acquainted with the most modern medical equipment. The Clinic of RostSMU includes 20 inpatient units with 675 beds (including 125-day care beds), 2 Departments of Anesthesiology and Resuscitation, Consultative Outpatient Department, a Dental Department, Medical Center, Сenter of Medical Ophthalmology, 13 medical-diagnostic subdivisions, Blood Technology Center.</p>
<p>Today the clinic staff consists of 353 physicians, including 11 professors, 28 Doctors of Medical Science and 52 Ph.D.’s; more than 30 employees of the university are the main external specialists and experts of the Ministry of Health of the Russian Federation in the Southern Federal District, the Ministry of Health of the Rostov region and the Municipal Council «Health Management in Rostov-on-Don». In recent years the centralization and automation of many diagnostic and medical departments of our University allowed us to concentrate and rationally use the staff, medical equipment, improve the quality of work of each structural unit and create a technology of the continuous medical-diagnostic process.</p>
<h1>Faculty on Training Foreign Students and Postgraduates :</h1>
<ul>
<li>General Medicine – 6 years</li>
<li>Pediatrics – 6 years</li>
<li>Dentistry — 5 years</li>
<li>Pharmacy – 5 years</li>
<li>Preparatory course- 10/12 months </li>
  </ul>
<h2>Admission Process: For MBBS Course at Rostov State Medical University</h2>
<p>The admissions are open for undergraduate courses in Rostov state medical university from 20 June to 10 September of every year. If the documents of candidates meet the eligibility criteria then he/she will get an opportunity to study MBBS at Rostov State Medical University.</p>
<ul>
<li>Step 1: Fill the university application form with all the required documents.</li>
<li>Step 2: After receiving your application university and an official representative will review your Eligibility criteria, documents, whether your eligible or not as per university criteria. If you are eligible then the university will issue you an admission letter.</li>
<li>Step 3: After admission letter, the university will apply for an invitation letter which will take 25-28 working days. (From Ministry of Immigration, Russia)</li>
<li>Step 4: After receiving the invitation letter, the student has to apply for the visa in the Russian Embassy of the respective country.</li>
<li>Note please: Countries, where we don’t have representatives only these countries students, have to apply for the visa individually. For Indian students; All work will be done by<a href="http://www.rrec.com.ru/"> RREC team Officials only.</a></li>
</ul>
<h3>Documents Required: For an Admission Process:</h3>
<ul>
  <li>Filled application form with affixed passport size photo (35*45 mm / Matte paper).</li>
  <li>Original Valid Passport with a minimum duration of 3 years @ time of application.</li>
  <li>Date of Birth Certificate.</li>
  <li>Original 10th Class Mark sheet &amp; Certificate.</li>
  <li>Original 12th Class Mark sheet &amp; Certificate (For India), For other countries only secondary school certificate.</li>
  <li>Medical Fitness Certificate from Government or Private Hospital.</li>
</ul>
<h4>RREC is the only Admission partner of Rostov State Medical University for Foreign Students.</h4>

<h5>To know more about university, campus, students feedback:</h5>

<iframe width="100%" height="315" src="https://www.youtube.com/embed/cn1LvbvhNvU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          </Col>
          </Row>
       </Container>
<InnerBottom />
      </Fragment>
    )
  }
}

export default Rostov_state_medical_university
